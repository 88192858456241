import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { OpenReleaseButtons } from 'components/common/OpenReleaseButtons';
import { LoadingModal } from 'components/common/LoadingModal';
import api from 'helpers/api';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/en-gb';
import { SecondaryNav } from 'components/common/SecondaryNav';
import { ReactComponent as Helmet } from 'assets/images/icons/v2/Helmet.svg';
import { ReactComponent as Bicicle } from 'assets/images/icons/v2/Bicicle.svg';
import { ReactComponent as Scooter } from 'assets/images/icons/v2/Scooter.svg';

const styles = {
  sizeText: {
    fontSize: '3.5vw', 
    fontWeight: '600',
    marginTop: '1vw'
  },

  overflowRow: {
    overflowY: 'hidden',
  }
};

export const GreenZoneMyLokkers = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  const [ loading, setLoading ] = useState(false);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  
  const { state } = useLocation();

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  const releaseLokker = (lokker) => {
    navTo('/release-lokker', { state: { locker: lokker, lockers: state.lockers, phoneNumber: state.phoneNumber, pin: state.pin, numberPrefix: state.numberPrefix  } })
  };

  const openLokker = (lokker) => {
    setIsSubmitting(true);
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/openLockerWithPin?locationId=${process.env.REACT_APP_LOCATION_ID}&number=${state.phoneNumber}&pin=${state.pin}&lockerId=${lokker.id}&numberPrefix=${state.numberPrefix}`)
    .then(function (response) {
      console.log(response);
      if (response?.data?.ResultCode === 1) {
        console.log(response?.data?.ResultCode)
        navTo('/lokker-opened', { state: { locker: lokker } })
      } else {
        console.log(response?.data?.Result);
      }
    })
    .catch(function (err) {
      console.log(err);
    })
    .finally(() => {
      setLoading(false)
      setIsSubmitting(false);
    });
  };

  const getIcon = (size) => {
    if(size === 'S') return <Helmet height="30" width="30" />
    if(size === 'M') return <Scooter height="30" width="30" />
    if(size === 'L') return <Bicicle height="30" width="30" />
  }
  
  return(
    state &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative px-3">
      <LoadingModal isLoading={loading} />
      <SecondaryNav secNavText={state.lockers.length !== 0 ? 'app.openOrReleaseLokker' : 'app.noLockerAssociated'}  navToURL="/"/>
      
      <Row style={styles.overflowRow}>
      {
        Object.keys(state.lockers).map((key, index) => 
          <Row key={index} className="mb-3 m-0">
            <MenuButton
              text={`${t.messages['app.doorLabel']} ${state.lockers[key].number}`}
              subtext={`${t.messages['app.orderAt']} ${moment(state.lockers[key].statusChangedAt).format('HH:mm')}`}
              icon={
                <Row>
                  <Col xs="12" className="d-flex flex-column">
                      <span style={styles.sizeText}>
                        {getIcon(state.lockers[key].size)}
                      </span>
                    </Col>
                </Row>
              }
            />
            <OpenReleaseButtons 
              releaseLokker={() => releaseLokker(state.lockers[key])} 
              openLokker={isSubmitting ? () => null : () => openLokker(state.lockers[key])}
              disabled={isSubmitting}
            />
          </Row>
        )
      }
      </Row>

    </TransitionView>
  )
}