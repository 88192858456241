import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { MenuButton } from 'components/common/MenuButton';
import { PrimaryButtonIcon } from 'components/common/PrimaryButtonIcon';
import { ReactComponent as AcessoFacil } from 'assets/images/icons/v1/AcessoFacil.svg';
import { ReactComponent as Lokker } from 'assets/images/icons/v1/Lokker.svg';
import { ReactComponent as Frio } from 'assets/images/icons/v1/Frio.svg';
import api from 'helpers/api';
import { disabledOpacity } from 'config/appConfig';
import { LoadingModal } from 'components/common/LoadingModal';
import { SecondaryNav } from 'components/common/SecondaryNav';
 
const styles = {
  accessibleLocker: {
    alignItems: 'end'
  },
  sizeText: {
    fontWeight: '600',
    fontSize: '2vw',
    width: 'fit-content'
  }
};

export const GreenZoneAccessibleLokkers = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  const { state } = useLocation();

  const [loading, setLoading] = useState(true);
  const [lockers, setLockers] = useState('');

  const normalLockersAvailable = () => {
    return Object.keys(lockers).filter(function (key) {
      return lockers[key].enabled === 1 && lockers[key].status === 0 && lockers[key].locker_metra_status === 2 && lockers[key].lockerType === 0 && lockers[key].accessibility === 1;
    });
  }

  const coldLockersAvailable = () => {
    return Object.keys(lockers).filter(function (key) {
      return lockers[key].enabled === 1 && lockers[key].status === 0 && lockers[key].locker_metra_status === 2 && lockers[key].lockerType === 1 && lockers[key].accessibility === 1;
    });
  }

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  useEffect(() => {
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/lockersByPlace/${process.env.REACT_APP_LOCATION_ID}`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          setLockers(response?.data?.Data?.Lockers)
        } else {
          console.log(response?.data?.Result);
        }
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  },[]);

  return(
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative px-3">
      <LoadingModal isLoading={loading} />
      <SecondaryNav secNavText="app.pickLockerType" navToURL="/" />

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.lockerTypeNormalAccessible']}
          subtext={`${normalLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={<Col className="d-flex flex-column justify-content-end align-items-end">
          <Row className="mb-3">
            <Lokker height={34} className="p-0" style={{opacity: normalLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/>
          </Row>
          <Row className="d-flex flex-row justify-content-center align-items-center">
            <span style={{...styles.sizeText, opacity: normalLockersAvailable().length !== 0 ? '1' : disabledOpacity }}>M</span>
          </Row>
        </Col>}
          onClick={() => navTo('/lokker-size', { state: { lockers: lockers, type: 0, accessibility: 1 } })}
          disabled={normalLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row className="m-0">
        <MenuButton
          text={t.messages['app.lockerTypeColdAccessible']}
          subtext={`${coldLockersAvailable().length} ${t.messages['app.availableLockers']}`}
          icon={<Col className="d-flex flex-column justify-content-end align-items-end">
            <Row className="mb-3">
              <Frio height={34} className="p-0" style={{opacity: coldLockersAvailable().length !== 0 ? '1' : disabledOpacity}}/>
            </Row>
            <Row className="d-flex flex-row justify-content-center align-items-center">
              <span style={{...styles.sizeText, opacity: coldLockersAvailable().length !== 0 ? '1' : disabledOpacity }}>M</span>
            </Row>
          </Col>}
          onClick={() => navTo('/lokker-size', { state: { lockers: lockers, type: 1, accessibility: 1 } })}
          disabled={coldLockersAvailable().length !== 0 ? false : true}
        />
      </Row>

      <Row style={styles.accessibleLocker} className="h-100 pb-4 m-0">
        <div className="m-0 p-0">
          <PrimaryButtonIcon
            text={t.messages['app.exitAccessibleLockers']}
            onClick={() => navTo('/lokker-type', { state: {...state } })}
            icon={<AcessoFacil height={34} />}
          />
        </div>
      </Row>
    </TransitionView>
  )
}