import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { LockerInfo } from 'components/common/LockerInfo';
import { useIntl } from 'react-intl';
import { Row } from 'reactstrap';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { FormattedMessage } from 'react-intl';
import { colors} from 'config/colors';

export const GreenZoneLokkerOpened = () => {
  const t = useIntl();
  let navigate = useNavigate();
  const { state } = useLocation();
  let { transitionType, handleTransition} = useRouterTransition(navigate);

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  return(
    state &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-start px-3">
      <Row className="m-0">
        <LockerInfo zone={process.env.REACT_APP_ZONE} text={<FormattedMessage id='app.lockerHasBeenOpened' values={{ lokkerNum: state.locker.number }}/>} number={state.locker.number} size={state.locker.size} type={state.locker.lockerType} />
        <PrimaryButton txtColor="light" text={t.messages['app.goBackToMenu']} color="white" border={colors['grey'].light} onClick={() => navTo('/')} />
      </Row>
    </TransitionView>
  );
}