import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { Row } from 'reactstrap';
import { LockerInfo } from 'components/common/LockerInfo';
import { PrimaryButton } from 'components/common/PrimaryButton';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';

export const GreenZoneLockerAssigned = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  const { state } = useLocation();

  const [showLockerOpenConfirmation, setShowLockerOpenConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  const goHome = () => {
    navTo('/');
  };

  const navTo = (url, state) =>{
    handleTransition (url, state)
  };

  const openLocker = () => {
    setIsSubmitting(true);
    setLoading(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/openLockerWithPin?locationId=${process.env.REACT_APP_LOCATION_ID}&pin=${state.pin}&number=${state.number}&lockerId=${state.lockers.id}&numberPrefix=${state.numberPrefix}`)
      .then(function (response) {
        console.log(response);
        if (response?.data?.ResultCode === 1) {
          console.log(response?.data);
          setShowLockerOpenConfirmation(true);
        } else {
          console.log('Error');
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })   
      .finally(() => {
        setIsSubmitting(false);
        setLoading(false);
      });
  };

  if(showLockerOpenConfirmation) return(
    state &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-start px-3">
      <LoadingModal isLoading={loading} />
      <Row className="m-0">
        <LockerInfo zone={process.env.REACT_APP_ZONE} txtColor="light" text={`${t.messages['app.YourLockerHasBeenOpened']}`} number={state.lockers.locker_metra_id} size={state.lockers.size} type={state.lockers.lockerType} />
      </Row>
      <Row className="my-4 m-0">
        <PrimaryButton txtColor="light" text={t.messages['app.exit']} onClick={goHome} isEnabled={!isSubmitting}/>
      </Row>
    </TransitionView>
  );
  return(
    state &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-start position-relative px-3">
      <LoadingModal isLoading={loading} />
      <Row className="m-0">
        <LockerInfo zone={process.env.REACT_APP_ZONE} text={`${t.messages['app.assignedLockerIs']} ${state.lockers.locker_metra_id}`} number={state.lockers.locker_metra_id} size={state.lockers.size} type={state.lockers.lockerType} />
      </Row>
      <Row className="my-4 m-0">
        <PrimaryButton txtColor="light" text={t.messages['app.openLocker']} onClick={isSubmitting ? () => null : openLocker} isEnabled={!isSubmitting}/>
      </Row>
    </TransitionView>
  );
};