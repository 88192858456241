import React, { useState, useEffect } from 'react';
import { Row, Col, Label } from 'reactstrap';
import { useNavigate } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { useIntl } from 'react-intl';
import { PrimaryButton } from 'components/common/PrimaryButton';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';
import { colors } from 'config/colors';
import SelectCountryPrefix from 'components/common/SelectCountryPrefix';
import { countryCodes } from 'config/numberPrefix';
import PinInput from "react-pin-input";

export const GreenZoneRecoverPin = () => {
  const t = useIntl();
  let navigate = useNavigate();
  let { transitionType, handleTransition } = useRouterTransition(navigate);
  const [selectedInput, setSelectedInput] = useState('phoneNumber');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmPinAnimation, setConfirmPinAnimation] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const [phoneNumberSize, setPhoneNumberSize] = useState(selectedCountry.characters);

  const invalidInputAnimationDuration = 0.7;

  const handleChangePhoneNumber = (val) => {
    // delete last char from phoneNumber
    if (val === '#') setPhoneNumber(phoneNumber.slice(0, -1));
    // add char to phoneNumber
    // if last char also go to next input
    else if (phoneNumber.length === phoneNumberSize - 1) {
      setPhoneNumber(phoneNumber + val);
    }
    else if (phoneNumber.length < phoneNumberSize) setPhoneNumber(phoneNumber + val);
  };

  const navTo = (url, state) => {
    handleTransition (url, state)
  };

  const handleNumpadInput = (val) => {
    // when user presses asterisk clear everything
    if (val === '*') {
      setPhoneNumber('');
      setSelectedInput('phoneNumber');
    }
    else {
      // handle specific input
      switch (selectedInput) {
        case 'phoneNumber':
          handleChangePhoneNumber(val)
          break;
        default:
          break;
      }
    }
  };

  const isPhoneNumberValid = () => {
    const pattern = new RegExp(`^\\d{${phoneNumberSize}}$`);
    if (phoneNumber.match(pattern)) {
      // is valid
      return true;
    }
  };

  const validateInput = () => {
    if (isPhoneNumberValid()) {
      // input is valid
      return true;
    } else {
      // input is invalid
      return false;
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    setIsSubmitting(true);
    api.post(`${process.env.REACT_APP_API_URL}api/mar-shopping/recoverPin`, {
      number: phoneNumber,
      numberPrefix: selectedCountry.dial_code,
    })
      .then(function (response) {
        if (response?.data?.ResultCode === 1) {
          console.log(response?.data?.Lockers);
          navTo('/sms-sent');
        } else if (response?.data?.Description === 'Invalid PIN') {
          console.log(response?.data?.Description);
          setConfirmPinAnimation('shake');
          setTimeout(() => {
            setSelectedInput('phoneNumber');
            setPhoneNumber('');
          }, invalidInputAnimationDuration * 1000);
        } else {
          console.log('Unknow Error');
          setConfirmPinAnimation('shake');
          setTimeout(() => {
            setSelectedInput('phoneNumber');
            setPhoneNumber('');
          }, invalidInputAnimationDuration * 1000);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setConfirmPinAnimation('shake');
        setTimeout(() => {
          setSelectedInput('phoneNumber');
          setPhoneNumber('');
        }, invalidInputAnimationDuration * 1000);
      })
      .finally(() => {
        setLoading(false);
        setIsSubmitting(false);
      });
  };

  const goBack = () => {
    navTo('/');
  };

  // logic to stop handleSubmit automatic when user press check key on android keyboard
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <TransitionView animation="fade-down" transitionType={transitionType} className="position-relative justify-content-start">
      <LoadingModal isLoading={loading} />
      <Row
        style={{
          backgroundColor: colors['beije'].light,
          padding: '7vw 0 7vw 0'
        }}
      >
        <Col xs="12" md="12" className="m-0">
          <SelectCountryPrefix 
            setPhoneNumberSize={setPhoneNumberSize}
            setPhoneNumber={setPhoneNumber}
            setSelectedInput={setSelectedInput}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
        </Col>
        <Col xs="12" md="12" className="m-0">
          <Label for="phoneNumber" style={{ fontSize: '2.5vw', fontWeight: '600' }}>{t.messages['app.phoneNumber']}</Label>
          <PinInput 
            length={phoneNumberSize} 
            initialValue={phoneNumber}
            focus 
            type="numeric" 
            inputMode="numeric" 
            style={{padding: '10px', height: '0 !important' }}  
            inputStyle={{ border: 'none', borderBottom: '1px solid black', borderRadius: 0, width: '20px', height: '10px !important' }}
            onChange={(value) => setPhoneNumber(value)}
          />
        </Col>
      </Row>
      <Row className="m-0 px-3">
        <PrimaryButton
          text={t.messages['app.confirm']}
          onClick={handleSubmit}
          isEnabled={validateInput()}
          color="yellow"
          txtColor="light"
        />
        <PrimaryButton
          text={t.messages['app.cancel']}
          onClick={goBack}
          color="white"
          border
          noBackground
        />
      </Row>
    </TransitionView>
  )
}