import React from 'react'
import { Row, Col } from 'reactstrap'
import { ReactComponent as ThermalCold } from 'assets/images/icons/v2/ThermalCold.svg';
import { ReactComponent as ThermalHot } from 'assets/images/icons/v2/ThermalHot.svg';

const LockerIconCold = ({ size, type }) => {
  return (
    <Row >
      {type.toString() === '1'
        ?
        <Col md="12">
          <ThermalCold width={size} height={size} />
        </Col>
        :
        <Col md="12">
          <ThermalHot width={size} height={size} />
        </Col>
      }
    </Row>
  )
}

export default LockerIconCold