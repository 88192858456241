import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { useNavigate, useLocation } from 'react-router';
import { TransitionView } from 'components/common/transition-view';
import { useRouterTransition } from 'hooks/useRouterTransition';
import { FormattedMessage, useIntl } from 'react-intl';
import { PrimaryButton } from 'components/common/PrimaryButton';
import { MessageBox } from 'components/common/MessageBox';
import api from 'helpers/api';
import { LoadingModal } from 'components/common/LoadingModal';

const styles = {
  headerText: {
    fontSize: '4vw',
    fontWeight: '600'
  }
};

export const GreenZoneReleaseLokker = () => {
  const t = useIntl();
  let navigate = useNavigate();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let { transitionType, handleTransition } = useRouterTransition(navigate);


  const navTo = (url, state) =>{
    handleTransition (url, state)
  };
  
  const releaseLokker = () => {
    setLoading(true);
    setIsSubmitting(true);
    api.get(`${process.env.REACT_APP_API_URL}api/mar-shopping/unassignLocker?locationId=${process.env.REACT_APP_LOCATION_ID}&number=${state.phoneNumber}&pin=${state.pin}&lockerId=${state.locker.id}&numberPrefix=${state.numberPrefix}`)
    .then(function (response) {
      console.log(response);
      if (response?.data?.ResultCode === 1) {
        console.log(response?.data?.ResultCode)
        navTo('/lokker-released', { state: { lokkerNumber: state.locker.number, lokkerSize: state.locker.size, lokkerType: state.locker.lockerType }})
      } else {
        console.log(response?.data?.Result);
      }
    })
    .catch(function (err) {
      console.log(err);
    })
    .finally(() => {
      setLoading(false);
      setIsSubmitting(false);
    });
  };

  return(
    state &&
    <TransitionView animation="fade-down" transitionType={transitionType} className="justify-content-start position-relative px-3">
      <LoadingModal isLoading={loading} />
      <Row className="py-1">
        <span style={styles.headerText}>
          <FormattedMessage id="app.releaseLabel" values={{lokkerId: state.locker.number }}/>
        </span>
      </Row>
      <Row>
        <MessageBox lokkerId={state.locker.number} />
      </Row>
      <Row className="m-0 mb-1">
        <PrimaryButton txtColor="light" text={t.messages['app.releaseLokkerLabel']} onClick={isSubmitting ? () => null : () => releaseLokker(state.lokkerId)} enabled={!isSubmitting} />
      </Row>
      <Row className="m-0">
        <PrimaryButton noBackground text={t.messages['app.cancel']} color="white" border="lightGrey" onClick={() => navTo('/my-lokkers', { state: {...state} })}/>
      </Row>
    </TransitionView>
  )
}